html, body, header {
    background-color: #131313;
    color: #DFDFDF;
}
html, body, header, footer, div {

    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

html {
    background-color: #050505;
}

body {
    max-width: 1200px;
    margin: 0px auto;
}

a {
    text-decoration: none;
    color: inherit;
}


::-webkit-scrollbar {
    display: none;
}

